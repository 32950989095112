import { createStore } from "vuex";
import { RootState } from "../interfaces";

import { application } from "./modules/application";
import { company } from "./modules/company";
import { scope } from "./modules/scope";
import { user } from "./modules/user";
import { applications } from "./modules/applications";
import { access_grant } from "./modules/access_grant";
import { activity_log } from "./modules/activity_log";
import { SHOW_TOAST } from "@/store-types";
import { useToast, TYPE as ToastType, POSITION } from "vue-toastification";

const toast = useToast();

export default createStore<RootState>({
  modules: {
    applications,
    application,
    company,
    scope,
    user,
    access_grant,
    activity_log,
  },
  actions: {
    [SHOW_TOAST](
      _,
      opt: {
        message: string;
        type?: ToastType;
        onClose?: () => void;
        timeout?: number;
      }
    ): void {
      toast(opt.message, {
        type: opt.type,
        onClose: opt.onClose,
        toastClassName: "mdc-toast",
        hideProgressBar: true,
        closeButton: false,
        position: POSITION.TOP_CENTER,
        timeout: opt.timeout,
      });
    },
  },
});
