import { mixpanelInstance } from "@/utils/mixpanel";
import { eventMapping } from "../mapping-event";
import { userProperties } from "../metadata";
export const emitMixpanelEvent = async (
  event: string,
  eventProperties: any
): Promise<void> => {
  const eventName = eventMapping(event);
  if (eventName) {
    // custom event properties
    const user = await userProperties();
    const properties = {
      ...user,
      ...eventProperties,
    };
    mixpanelInstance.emitEvent(eventName, properties);
  } else {
    console.error(`Event name not found for ${event}`);
  }
};
