/**
 * Popup vuex module
 */
import { Module, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/interfaces";
import api from "@/internal-api";
import { COMPANY_SET_COMPANIES, COMPANY_FETCH_COMPANIES } from "@/store-types";
import { Company, CompanyState } from "@/interfaces/company";

/**
 * Initial state
 */
const state: CompanyState = {
  companies: [],
};

/**
 * Module mutation
 */
const mutations: MutationTree<CompanyState> = {
  [COMPANY_SET_COMPANIES](state: CompanyState, companies: Array<Company>) {
    state.companies = companies;
  },
};

/**
 * Module action
 */
const actions: ActionTree<CompanyState, RootState> = {
  async [COMPANY_FETCH_COMPANIES]({ commit }) {
    const { result, error } = await api.companies.getCompanies();

    if (error) {
      throw error;
    }

    commit(
      COMPANY_SET_COMPANIES,
      result.data.map(
        (payload: {
          id: any;
          attributes: {
            platforms: any;
            company_size: any;
            name: any;
          };
        }) => ({
          id: payload.id,
          name: payload.attributes.name,
          companySize: payload.attributes.company_size,
          platforms: payload.attributes.platforms,
        })
      )
    );
  },
};

export const company: Module<CompanyState, RootState> = {
  state,
  mutations,
  actions,
};
