/**
 * API Plugin
 */

import axios, { Method, AxiosRequestConfig } from "axios";
import { APIResponse, JsonApiRequest } from "./interfaces";
import config from "./config";

/**
 * Initialize axios
 */
const token = (document.querySelector("[name=csrf-token]") as HTMLMetaElement)
  ?.content;

const httpWrapper = async (
  method: Method,
  endpoint: string,
  data: Record<string, unknown> = {}
): Promise<APIResponse> => {
  const options: AxiosRequestConfig = {
    method: method,
    url: `/api/internal${endpoint}`,
    headers: {
      "X-CSRF-TOKEN": token,
      "Content-Type": "application/json",
    },
  };

  if (method.toLocaleLowerCase() === "get") {
    options.params = data;
  } else {
    options.data = data;
  }

  try {
    const response = await axios(options);
    return { result: response.data, error: null };
  } catch (error) {
    console.log(error);

    if ("response" in error) {
      return { result: error.response, error: error };
    }

    return { result: "error", error: error };
  }
};

// Set module
export default {
  applications: {
    async getHmacAuthApplications(): Promise<APIResponse> {
      return httpWrapper("get", "/hmac_auth_applications");
    },
    async getHmacAuthApplication(uuid: string): Promise<APIResponse> {
      return httpWrapper("get", "/hmac_auth_applications/" + uuid);
    },
    async createHmacAuthApplication(
      data: JsonApiRequest
    ): Promise<APIResponse> {
      if (["sandbox", "production"].includes(config.railsEnv)) {
        return httpWrapper("post", "/hmac_auth_applications", data);
      }
      return httpWrapper("post", "/mdc_api/hmac_auth_applications", data);
    },
    async updateHmacAuthApplication(
      uuid: string,
      data: JsonApiRequest
    ): Promise<APIResponse> {
      if (config.railsEnv === "sandbox") {
        return httpWrapper("put", "/hmac_auth_applications/" + uuid, data);
      }
      return httpWrapper(
        "put",
        "/mdc_api/hmac_auth_applications/" + uuid,
        data
      );
    },
    async deleteHmacAuthApplication(
      uuid: string,
      data: JsonApiRequest
    ): Promise<APIResponse> {
      if (config.railsEnv === "sandbox") {
        return httpWrapper("delete", "/hmac_auth_applications/" + uuid);
      }
      return httpWrapper(
        "delete",
        "/mdc_api/hmac_auth_applications/" + uuid,
        data
      );
    },
    async regenerateSecretHmacAuthApplication(
      uuid: string,
      data: JsonApiRequest
    ): Promise<APIResponse> {
      if (config.railsEnv === "sandbox") {
        return httpWrapper(
          "post",
          "/hmac_auth_applications/" + uuid + "/regenerate_secret"
        );
      }
      return httpWrapper(
        "post",
        "/mdc_api/hmac_auth_applications/" + uuid + "/regenerate_secret",
        data
      );
    },
  },

  companies: {
    async getCompanies(): Promise<APIResponse> {
      return httpWrapper("get", "/users/me/companies");
    },
  },

  scopes: {
    async getScopes(
      params: Record<string, unknown> = {}
    ): Promise<APIResponse> {
      return httpWrapper("get", "/authorized_scopes", params);
    },
  },

  accessGrants: {
    async getAccessGrant(uuid: string): Promise<APIResponse> {
      return httpWrapper("get", "/access_grants/" + uuid);
    },
    async approve(uuid: string): Promise<APIResponse> {
      return httpWrapper("post", "/access_grants/" + uuid + "/approve", {});
    },
  },

  activityLog: {
    async getActivityLog(data: JsonApiRequest): Promise<APIResponse> {
      return httpWrapper("get", "/mdc_api/activity_log", data);
    },
  },
};
