import { listen } from "@/utils/event";
import { event } from "../enums/applications";
import { emitMixpanelEvent } from "../emit";

listen(
  event.SubmitCreateApplication,
  (dataOptions: Record<string, unknown>) => {
    emitMixpanelEvent(event.SubmitCreateApplication, dataOptions);
  }
);

listen(event.UpdateApplication, (dataOptions: Record<string, unknown>) => {
  emitMixpanelEvent(event.UpdateApplication, dataOptions);
});

listen(
  event.RegenerateSecretApplication,
  (dataOptions: Record<string, unknown>) => {
    emitMixpanelEvent(event.RegenerateSecretApplication, dataOptions);
  }
);

listen(event.DeleteApplication, (dataOptions: Record<string, unknown>) => {
  emitMixpanelEvent(event.DeleteApplication, dataOptions);
});
