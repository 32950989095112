/**
 * Popup vuex module
 */
import { Module, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/interfaces";
import api from "@/internal-api";
import {
  FETCH_ACTIVITY_LOGS,
  SET_DATA_ACTIVITY_LOGS,
  SET_PAGINATION_ACTIVITY_LOGS,
} from "@/store-types";
import { ActivityLogState, ActivityLogResponse } from "@/interfaces";
import { ActivityLogQueryParams } from "@/interfaces/activity_log";

/**
 * Initial state
 */
const state: ActivityLogState = {
  activityLogs: [],
  pagination: {
    page: 0,
    size: 0,
    next: 0,
    prev: 0,
    total_page: 0,
    total_record: 0,
  },
};

/**
 * Module mutation
 */
const mutations: MutationTree<ActivityLogState> = {
  [SET_DATA_ACTIVITY_LOGS](state: ActivityLogState, activityLogs: any) {
    state.activityLogs = activityLogs;
  },
  [SET_PAGINATION_ACTIVITY_LOGS](state: ActivityLogState, pagination: any) {
    state.pagination = pagination;
  },
};

/**
 * Module action
 */
const actions: ActionTree<ActivityLogState, RootState> = {
  /**
   * Fetch current applications data
   */
  async [FETCH_ACTIVITY_LOGS]({ commit }, request: ActivityLogQueryParams) {
    const { result, error }: ActivityLogResponse =
      await api.activityLog.getActivityLog({
        ...request,
      });
    if (error) {
      throw error;
    }
    commit(SET_DATA_ACTIVITY_LOGS, result.data);
    commit(SET_PAGINATION_ACTIVITY_LOGS, result.pagination);
  },
};

export const activity_log: Module<ActivityLogState, RootState> = {
  state,
  mutations,
  actions,
};
