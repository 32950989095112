import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import vueClickOutside from "@/directives/vue-click-outside";
import tooltip from "@/directives/tooltip";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";
import "@/events/mixpanel/listen";
import { init } from "js-utils/dist/browser-fingerprint";

import { initializeFlagsmith } from "./utils/flagsmith";
import "@fortawesome/fontawesome-free/css/all.css";

const app = createApp(App);

(async () => {
  await init();
  const i18n = createI18n({
    locale: "xx",
    fallbackLocale: "xx",
    messages: window.MDC_PAGEMETA.locale_messages,
  });

  try {
    await initializeFlagsmith();
    console.log("Flagsmith is ready to use");
  } catch (error) {
    console.warn("Running project without Flagsmith");
  }

  app.use(store);
  app.use(router);
  app.use(i18n);
  app.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 1,
    newestOnTop: true,
    timeout: 3000,
  });
  app.directive("click-outside", vueClickOutside);
  app.directive("tooltip", tooltip);
  app.mount("#app");
})();
