import config from "../config";
import mixpanel from "mixpanel-browser";
type EventEnum = string;
interface Metadata {
  CompanySSOID: string | undefined;
}
/**
 * Convert event enum to string.
 * @param {EventEnum} eventEnum Event enum
 * @returns {string} Mixpanel event name
 */
export const eventToString = (eventEnum: EventEnum): string => {
  return `${
    config.railsEnv === "production" ? "" : `[${config.railsEnv}] `
  }Mekari developer center - ${eventEnum}`;
};
/**
 * Set metadata for Mixpanel.
 * @param {Metadata} param0 Metadata object
 */
export const setMetadata = ({ CompanySSOID }: Metadata): void => {
  if (CompanySSOID) {
    mixpanel.identify(CompanySSOID);
    mixpanel.set_group("SSO Company ID", CompanySSOID);
    mixpanel.get_group("SSO Company ID", CompanySSOID).set({
      distinct_id: CompanySSOID,
      "SSO Company ID": CompanySSOID,
    });
  }
  mixpanel.people.set({
    "User ID": CompanySSOID,
    "Distinct ID": CompanySSOID,
  });
};
/**
 * Emit a Mixpanel event.
 * @param {EventEnum} eventEnum Event enum
 * @param {Record<string, any>} properties Event properties
 */
export const emitEvent = (
  eventEnum: EventEnum,
  properties: Record<string, any>
): void => {
  mixpanel.track(eventToString(eventEnum), properties);
};
/**
 * Initialize Mixpanel instance.
 * @returns Initialized Mixpanel instance
 */
const init = () => {
  if (config.mixpanelToken) {
    mixpanel.init(config.mixpanelToken, {
      debug: config.railsEnv === "development",
      ignore_dnt: true,
    });
  }
  return {
    setMetadata,
    emitEvent,
  };
};
export const mixpanelInstance = init();
