/**
 * Popup vuex module
 */
import { Module, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/interfaces";
import api from "@/internal-api";
import {
  APPLICATION_SET_HMAC_AUTH,
  APPLICATION_CREATE_HMAC_AUTH,
  APPLICATION_FETCH_HMAC_AUTH,
  APPLICATION_UPDATE_HMAC_AUTH,
  APPLICATION_SET_NAME,
  APPLICATION_DELETE_HMAC_AUTH,
  APPLICATION_REGENRATE_SECRET_HMAC_AUTH,
} from "@/store-types";
import {
  ApplicationState,
  CreateHmacAuthApplicationRequest,
  HmacAuthApplication,
  UpdateHmacAuthApplicationRequest,
  RegenerateSecretHmacAuthApplicationRequest,
} from "@/interfaces/application";

/**
 * Initial state
 */
const state: ApplicationState = {};

/**
 * Module mutation
 */
const mutations: MutationTree<ApplicationState> = {
  [APPLICATION_SET_HMAC_AUTH](
    state: ApplicationState,
    hmacAuthApplication: HmacAuthApplication
  ) {
    state.hmacAuthApplication = hmacAuthApplication;
  },
  [APPLICATION_SET_NAME](state: ApplicationState, name: string) {
    if (state.hmacAuthApplication === undefined) {
      return;
    }

    state.hmacAuthApplication.name = name;
  },
};

/**
 * Module action
 */
const actions: ActionTree<ApplicationState, RootState> = {
  async [APPLICATION_CREATE_HMAC_AUTH](
    { commit },
    request: CreateHmacAuthApplicationRequest
  ) {
    const { result, error } = await api.applications.createHmacAuthApplication({
      name: request.name,
      company_id: request.companyId,
      company_name: request.companyName,
      scopes: request.scopes,
      page_name: request.pageName,
    });

    if (error) {
      throw error;
    }

    commit(APPLICATION_SET_HMAC_AUTH, {
      id: result.data.id,
      name: request.name,
      companyId: request.companyId,
      createdAt: result.data.attributes.created_at,
      clientId: result.data.attributes.client_id,
      clientSecret: result.data.attributes.client_secret,
    });
  },
  async [APPLICATION_FETCH_HMAC_AUTH]({ commit }, uuid: string) {
    const { result, error } = await api.applications.getHmacAuthApplication(
      uuid
    );

    if (error) {
      throw error;
    }

    commit(APPLICATION_SET_HMAC_AUTH, {
      id: result.data.id,
      name: result.data.attributes.name,
      companyId: result.data.attributes.company_id,
      createdAt: result.data.attributes.created_at,
      clientId: result.data.attributes.client_id,
      scopes: result.data.attributes.scopes,
    });
  },
  async [APPLICATION_UPDATE_HMAC_AUTH](
    { commit },
    request: UpdateHmacAuthApplicationRequest
  ) {
    const { result, error } = await api.applications.updateHmacAuthApplication(
      request.uuid,
      { name: request.name, page_name: request.pageName }
    );

    if (error) {
      throw error;
    }

    commit(APPLICATION_SET_HMAC_AUTH, {
      id: result.data.app_id,
      name: result.data.name,
      companyId: result.data.company_id,
    });
  },
  async [APPLICATION_DELETE_HMAC_AUTH](
    { commit },
    request: RegenerateSecretHmacAuthApplicationRequest
  ) {
    const { error } = await api.applications.deleteHmacAuthApplication(
      request.uuid,
      { page_name: request.pageName }
    );

    if (error) {
      throw error;
    }
  },
  async [APPLICATION_REGENRATE_SECRET_HMAC_AUTH](
    { commit },
    request: RegenerateSecretHmacAuthApplicationRequest
  ) {
    const { result, error } =
      await api.applications.regenerateSecretHmacAuthApplication(request.uuid, {
        page_name: request.pageName,
      });

    if (error) {
      throw error;
    }

    commit(APPLICATION_SET_HMAC_AUTH, {
      id: result.data.id,
      name: result.data.attributes.name,
      companyId: result.data.attributes.company_id,
      createdAt: result.data.attributes.created_at,
      clientId: result.data.attributes.client_id,
      clientSecret: result.data.attributes.client_secret,
      scopes: result.data.attributes.scopes,
    });
  },
};

export const application: Module<ApplicationState, RootState> = {
  state,
  mutations,
  actions,
};
