const PREFIX = "application";
/**
 * Event enum related to paywall
 * @enum {EventEnum}
 */
export const event = {
  SubmitCreateApplication: `${PREFIX}.submit-create-application`,
  UpdateApplication: `${PREFIX}.update-application`,
  RegenerateSecretApplication: `${PREFIX}.regenerate-secret-application`,
  DeleteApplication: `${PREFIX}.delete-application`,
};
export const evenType = {
  EventSubmitCreateApplication: "Submit Create Application",
  EventUpdateApplication: "Update Application",
  EventRegenerateSecretApplication: "Regenerate Secret Application",
  EventDeleteApplication: "Delete Application",
};
