<template>
  <router-view />
</template>

<style lang="scss">
$prefix: null !default;
$is-using-rubygems: null !default;

@import "mekari-ui-toolkit/dist/scss/vendor-extensions/_main";
@import "mekari-ui-toolkit/dist/scss/vendors/_bootstrap";
@import "mekari-ui-toolkit/dist/scss/base/_text";
@import "mekari-ui-toolkit/dist/scss/mixins/_main";
@import "mekari-ui-toolkit/dist/scss/utilities/grid";
@import "mekari-ui-toolkit/dist/scss/utilities/utilities";
@import "mekari-ui-toolkit/dist/scss/components/config/_button";
@import "mekari-ui-toolkit/dist/scss/components/config/_dropdowns";
@import "mekari-ui-toolkit/dist/scss/components/config/_form";
@import "mekari-ui-toolkit/dist/scss/components/config/_modal";
@import "mekari-ui-toolkit/dist/scss/components/config/_pagination";
@import "mekari-ui-toolkit/dist/scss/components/config/_tables";
@import "mekari-ui-toolkit/dist/scss/components/config/_tabs";
@import "mekari-ui-toolkit/dist/scss/components/config/_tooltip";

@import "@mekari/pixel/src/scss/main.scss";
@import "@/assets/scss/_toast";

html,
body {
  font-family: "Inter", sans-serif;
  font-feature-settings: "tnum" on, "lnum" on;
  background: #f3f5f7;
}

b,
strong {
  font-weight: 600;
}

i {
  display: inline-block;
}

svg {
  width: 100%;
}

#manage-container {
  position: relative;

  main {
    width: 100vw;
    height: calc(100vh - 82px);
    padding-left: 220px;
    overflow: scroll;
    transition: padding-left 0.3s;
  }

  .sidebar.collapsed + main {
    padding-left: 56px;
  }
}
</style>
