import flagsmith from "flagsmith";
import config from "../config";

export const initializeFlagsmith = async () => {
  await flagsmith.init({
    api: config.flagsmithApiUrl, // Ganti dengan URL API Flagsmith
    environmentID: config.flagsmithEnvironmentKey, // Ganti dengan Environment ID dari Flagsmith
    cacheFlags: true, // Opsional: Cache untuk performa
    // enableAnalytics: true, // Opsional: Untuk analitik
  });
};

export const getFlagValue = (flagName: string): boolean => {
  return flagsmith.hasFeature(flagName);
};

export const flagsmithInstance = flagsmith;
